
import Link from "next/link";
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import _ from 'lodash';
export default function HeaderBody(props) {
    const setShowMenu = props.setShowMenu;
    const router = useRouter();
    const [innerHeight, setInnerHeight] = useState(0);
    const [offset, setOffset] = useState(0);
    const [value, setValue] = useState('');
    const [search, setSearch] = useState(false);
    const type = {
        backgroundColor: "transparent",
    };
    const colorHeader = {
        backgroundColor: "none",
    };
    const [style, setStyle] = useState();

    useEffect(() => {
        if (window.scrollY >= 0 && window.scrollY <= 20 && window.innerWidth >= 991) {
            setStyle(type);
        }
        else {
            setStyle(colorHeader);
        }
        const onScroll = async () => {
            setOffset(window.pageYOffset);
            if (window.scrollY >= 0 && window.scrollY <= 20 && window.innerWidth >= 991) {
                setStyle(type);
            }
            else {
                setStyle(colorHeader);
            }
        };
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        setInnerHeight(window.innerHeight);
        return () => {
            window.removeEventListener('scroll', onScroll);
            if (window.scrollY >= 0 && window.scrollY <= 50 && window.innerWidth >= 991) {
                setStyle(type);
            }
            else {
                setStyle(colorHeader);
            }
        };

    }, []);

    const onchangevalue = ({ target }) => {
        const { value } = target;
        setValue(value);
    };

    const onSearchDelayed = _.debounce(() => searchDatA(), 100);

    const searchDatA = async () => {
        const keyword = value ? 'valueSearch=' + value : '';
        router.push('/jobs?' + keyword);
    };

    return (
        <header style={style} className={(router.pathname.includes('/jobs/') || router.pathname.includes('/environment') || router.pathname.includes('/why') ? ' cs-header ' : ' scroll ') + 'header'}>
            <div className='header-main header-main-show'>
                <div className='container'>
                    <div className='logo'>
                        <Link href='https://tcom.vn/'>
                            <a href='https://tcom.vn/' >
                                {/* anh logo TCOM màn desktop*/}
                                {router.pathname.includes('/environment') || router.pathname.includes('/jobs/')
                                    || router.pathname.includes('/why')
                                    ? <img src='/assets/images/logo_nb.svg' alt='TCOM' className='h-sp' />
                                    : <img src='/assets/images/logo.svg' alt='TCOM' className='h-sp' />}

                                {/* anh logo TCOM màn mobile */}
                                {router.pathname.includes('/environment')
                                    || router.pathname.includes('/why')
                                    ? <img src='/assets/images/logo_nb.svg' alt='TCOM' className='h-pc' />
                                    : <img src='/assets/images/logo.svg' alt='TCOM' className='h-pc' />}

                            </a>
                        </Link>
                    </div>

                    <nav className='primary-menu'>
                        <ul id='menu-main-menu' className='menu sf-menu'>
                            <li onClick={() => { setShowMenu(false); }} className={router.pathname === '/' ? 'menu-item active' : 'menu-item'} ><Link href='/'>Trang chủ</Link></li>
                            <li onClick={() => { setShowMenu(false); }} className={router.pathname.includes('/jobs') ? 'menu-item active' : 'menu-item'} ><Link href='/jobs'>Jobs</Link></li>
                            <li onClick={() => { setShowMenu(false); }} className={router.pathname.includes('/project') ? 'menu-item active' : 'menu-item'} ><Link href='/project'>Công nghệ</Link></li>
                            <li onClick={() => { setShowMenu(false); }} className={router.pathname.includes('/environment') ? 'menu-item active' : 'menu-item'} ><Link href='/environment'>Môi trường công việc</Link></li>
                            <li onClick={() => { setShowMenu(false); }} className={router.pathname.includes('/why') ? 'menu-item active' : 'menu-item'} ><Link href='/why'>Tại sao</Link></li>
                        </ul>
                    </nav>
                    <div className='header-right'>
                        <div className='header-search'>
                            {/* <Link href='/'>
                                <a href='/'>
                                    {router.pathname.includes('/environment')
                                        || router.pathname.includes('/why')
                                        ? <img src='/assets/images/search_nb.svg' alt='icon search' />
                                        : <img src='/assets/images/icon-search_sp.svg' alt='icon search' />}
                                </a>
                            </Link> */}
                            {
                                router.pathname.includes('/jobs/') && router.pathname.includes('/jobs')
                                    || router.pathname.includes('/environment') || router.pathname.includes('/why') || router.pathname.includes('/project') || router.pathname === '/'
                                    ? <div className='header-searchbar' style={search ? { borderRadius: 20, border: '1px solid #434343', backgroundColor: '#ccc', width: '85%', marginLeft: '34px' } : null}>
                                        {search && <input
                                            style={{ width: '85%' }}
                                            autoFocus={true}
                                            type='text'
                                            placeholder='Keyword'
                                            value={value}
                                            name='search-field'
                                            onChange={onchangevalue}
                                        />}
                                        <button className='header-btn-search' style={{ cursor: 'pointer' }} type='button' onClick={() => value ? (onSearchDelayed(), setSearch(!search), setValue('')) : setSearch(!search)}>
                                            {router.pathname.includes('/environment')
                                                || router.pathname.includes('/why') || router.pathname.includes('/jobs/')
                                                ? <img src='/assets/images/search_nb.svg' alt='icon search' />
                                                : <img src='/assets/images/icon-search_sp.svg' alt='icon search' />}
                                        </button>
                                    </div>
                                    : <div className='header-searchbar'>
                                        <button className='header-btn-search' >
                                            <img src='/assets/images/project/gg_menu.svg' alt='icon search' />
                                        </button>
                                    </div>
                            }


                        </div>
                        <button type='button' style={{ cursor: 'pointer' }} className='toggle-nav' data-target='navi' onClick={() => { setShowMenu(); }}>
                            <span />
                            {router.pathname.includes('/jobs/')
                                || router.pathname.includes('/environment')
                                || router.pathname.includes('/why')
                                ? <img src='/assets/images/gg_menu.svg' alt='' />
                                : <img src='/assets/images/icon-narbar.svg' alt='' />}
                        </button>
                    </div>
                </div>
            </div>
        </header>

    );

}