import Link from "next/link";
import PropTypes from 'prop-types';
import Head from 'next/head';
import { Form, message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { getAllServices } from '../redux/actions/services';
// const router = useRouter();
export default function Footer(props) {
    const urlRoot = process.env.NEXT_PUBLIC_WEB_URL || 'https://tcom.vn';
    const dispatch = useDispatch();
    const [service, setService] = useState([]);
    useEffect(() => {
        getListService();
    }, [dispatch]);
    const getListService = async () => {
        const data = await getAllServices({}, dispatch);
        if (data) {
            setService(data?.rows);
        }

    };
    return (
        <footer className='footer'>
            <div className='footer-main'>
                <div className='container-custom'>

                    <div className='c-row vi'>
                        <div className='row'>
                            <div className='col-12 col-xl-4' style={{ width: 374 }}>
                                <div className='box has-icon'>
                                    <div className='logo'>
                                        <Link href='/'>
                                            <img src='/assets/images/logo-footer.svg' alt='TCOM' />
                                        </Link>
                                    </div>
                                    <p className='textLogo'>Công ty công nghệ đa lĩnh vực<br />hàng đầu Việt Nam</p>
                                    <div className='iconLogo'>
                                        <ul>
                                            <li>
                                                <a href='https://www.facebook.com/TCOM.Corporation' target={'_blank'}>
                                                    <svg width='33' height='33' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                        <circle cx='16.5' cy='16.5' r='16.5' fill='white' />
                                                        <path d='M20.1116 10.2951H21.9233V7.13974C21.6107 7.09674 20.5358 7 19.2839 7C16.6718 7 14.8825 8.643 14.8825 11.6627V14.4419H12V17.9693H14.8825L14.8825 26.845H18.4165V17.9701H21.1824L21.6215 14.4427H18.4157V12.0125C18.4165 10.993 18.6911 10.2951 20.1116 10.2951Z' fill='#0063AA' />
                                                    </svg>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='https://www.linkedin.com/company/tcom-corporation/' target={'_blank'}>
                                                    <svg width='33' height='33' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                        <path d='M16.4999 0C25.6131 0 33 7.38787 33 16.5001C33 25.6122 25.6131 33 16.4999 33C7.38674 33 0 25.6121 0 16.5001C0 7.38798 7.38685 0 16.4999 0Z' fill='white' />
                                                        <path d='M9.29779 22.671H12.3874V12.3729H9.29779V22.671ZM21.3346 12.0162C19.8351 12.0162 18.4935 12.5637 17.5417 13.7724V12.339H14.4407V22.6711H17.5417V17.0837C17.5417 15.9029 18.6235 14.751 19.9785 14.751C21.3336 14.751 21.6677 15.9029 21.6677 17.0549V22.67H24.7574V16.8249C24.7573 12.7648 22.8351 12.0162 21.3346 12.0162ZM10.8282 11.3437C11.6821 11.3437 12.3751 10.6507 12.3751 9.79681C12.3751 8.94292 11.6821 8.25 10.8282 8.25C9.97428 8.25 9.28125 8.94303 9.28125 9.79692C9.28125 10.6508 9.97428 11.3437 10.8282 11.3437Z' fill='#0063AA' />
                                                    </svg>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='https://www.youtube.com/channel/UCmeadb8H2pD_05cFz0qvDVw'>
                                                    <svg width='33' height='33' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                        <circle cx='16.5' cy='16.5' r='16.5' fill='white' />
                                                        <path d='M24.6452 12.8745C24.5484 12.5115 24.3587 12.1805 24.095 11.9146C23.8313 11.6487 23.5028 11.4572 23.1425 11.3592C21.8163 11 16.5 11 16.5 11C16.5 11 11.1837 11 9.85748 11.3573C9.49703 11.4549 9.16842 11.6463 8.90465 11.9123C8.64089 12.1783 8.45126 12.5095 8.3548 12.8726C8 14.2102 8 17 8 17C8 17 8 19.7898 8.3548 21.1255C8.55022 21.8631 9.12701 22.4439 9.85748 22.6408C11.1837 23 16.5 23 16.5 23C16.5 23 21.8163 23 23.1425 22.6408C23.8749 22.4439 24.4498 21.8631 24.6452 21.1255C25 19.7898 25 17 25 17C25 17 25 14.2102 24.6452 12.8745ZM14.8114 19.5605V14.4395L19.2132 16.9809L14.8114 19.5605Z' fill='#0063AA' />
                                                    </svg>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div className='col-12 col-xl-3'>
                                <div className='ttl'>
                                    <span>TCOM VIỆT NAM</span>
                                    <img src='/assets/images/flat-vi.svg' alt='VI' />
                                </div>
                                <ul className='customLirr'>
                                    <li className='map'>
                                        <div className='titleMap' style={{ color: 'white' }}>
                                            <img src='/assets/images/iconFooter/akar-icons_location.svg' alt='map' />
                                            <span>Trụ sở chính</span>
                                        </div>
                                        <div>
                                            <div className='text-footer customPaddingR-45'>Số 26B/92, Tổ 11, phường Hoàng Văn Thụ, Hoàng Mai, Hà Nội, Việt Nam</div>
                                        </div>
                                    </li>
                                    <div className='TcomJP'>
                                        <div className='ttl'>
                                            TCOM JAPAN
                                            <img src='/assets/images/flat-jp.svg' alt='JP' />
                                        </div>
                                        <ul>
                                            <li className='map custom'>
                                                <div className='titleMap' style={{ color: 'white' }}>
                                                    <img src='/assets/images/iconFooter/akar-icons_location.svg' alt='map' />
                                                    <span>Trụ sở chính</span>
                                                </div>
                                                <div>
                                                    <div style={{ whiteSpace: "nowrap" }} className='text-footer'>〒190-0023 東京都立川市柴崎町3-8-5</div>
                                                </div>
                                            </li>
                                        </ul>

                                    </div>
                                </ul>
                            </div>
                            <div className='col-12 col-xl-3'>
                                <ul className='customLirr customMT-65'>
                                    <li className='map'>
                                        <div className='titleMap' style={{ color: 'white' }}>
                                            <img src='/assets/images/iconFooter/ep_office-building.svg' alt='map' />
                                            <span>Văn phòng Hà Nội</span>
                                        </div>
                                        <div>

                                            <div className='text-footer customPR-52'>Tầng 3 tòa nhà văn phòng Fafim A, Số 19 Nguyễn Trãi, Phường Khương Trung, Quận Thanh Xuân, Hà Nội</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-12 col-xl-2'>
                                <ul className='customMT65'>
                                    <li className='map'>
                                        <div className='titleMap' style={{ color: 'white' }}>
                                            <img src='/assets/images/iconFooter/ep_office-building.svg' alt='map' />
                                            <span style={{ whiteSpace: "nowrap" }}> Văn phòng Hồ Chí Minh</span>
                                        </div>
                                        <div>
                                            <div className='text-footer'>Tầng 2, Toà nhà số 102 Nguyễn Đình Chính, Phường 15, Quận Phú Nhuận, TP Hồ Chí Minh</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className='box box-dv'>
                            <div className='ttl'>CÁC DỊCH VỤ</div>
                            <ul className='link-list'>
                                {service.map((item, index) => {
                                    return (
                                        <li key={index}><a href={`/${urlRoot}/service/${item?.seo_url}`}>{item?.title}</a></li>
                                    );
                                })}

                                <li><a href={`${urlRoot}/offshore`}>{'Offshore'}</a></li>
                            </ul>
                        </div>
                        <div className='box customD-none'>
                            <div className='ttl'>GIỚI THIỆU</div>
                            <ul>
                                {/* <li><a href='https://tcom.vn/'>Trang chủ</a></li> */}
                                <li><a href='https://tcom.vn/about'>Về chúng tôi</a></li>
                                <li><a href='https://tcom.vn/service'>Dịch vụ</a></li>
                                <li><a href='https://tcom.vn/project'>Dự án</a></li>
                                <li><a href='https://tcom.vn/contact'>Liên hệ</a></li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className='c-row jp'>
                        <div className='box has-icon'>
                            <div className='ttl'>
                                TCOM JAPAN
                                <img src='/assets/images/flat-jp.svg' alt='JP' />
                            </div>
                            <ul>
                                <li className='map custom'>
                                    <div className='titleMap' style={{ color: 'white' }}>
                                        <img src='/assets/images/iconFooter/akar-icons_location.svg' alt='map' />
                                        <span>Trụ sở chính</span>
                                    </div>
                                    <div>
                                        <div className='text-footer'>Số 26B/92, Tổ 11, phường Hoàng Văn Thụ, Hoàng Mai, Hà Nội, Việt Nam</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className='box'>
                            <div className='ttl'>KEEP IN TOUCH</div>
                            <div className='box-social'>
                                <ul>
                                    <li>
                                        <a href='https://www.facebook.com/TCOM.Corporation' target='_blank'>
                                            <img src='/assets/images/icon-facebook.svg' alt='facebook' />
                                        </a>
                                    </li>
                                    <li>
                                        <a href='https://www.linkedin.com/company/tcom-corporation' target='_blank'>
                                            <img src='/assets/images/icon-in.svg' alt='in' />
                                        </a>
                                    </li>
                                    <li>
                                        <a href='https://www.messenger.com/t/TCOM.Corporation' target='_blank'>
                                            <img src='/assets/images/icon-messenger.svg' alt='messenger' />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div> */}
                    <div className='TcomJP-MB'>
                        <div className='ttl'>
                            TCOM JAPAN
                            <img src='/assets/images/flat-jp.svg' alt='JP' />
                        </div>
                        <ul>
                            <li className='map custom'>
                                <div className='titleMap' style={{ color: 'white' }}>
                                    <img src='/assets/images/iconFooter/akar-icons_location.svg' alt='map' />
                                    <span>Trụ sở Tokyo</span>
                                </div>
                                <div>
                                    <div style={{ whiteSpace: "nowrap" }} className='text-footer custom-text-footer-mt'>〒190-0023 東京都立川市柴崎町3-8-5</div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className='row customRowFooter2 pt-5'>
                        <div className='col-12 col-xl-4'>
                            <div className='info text-white'>
                                <h3 className='title'> GIỚI THIỆU</h3>
                            </div>
                            <div className='content'>
                                <ul>
                                    {/* <li>
                                        <Link href='https://tcom.vn'><a >Trang chủ</a></Link>
                                    </li> */}
                                    <li>
                                        <Link href='https://tcom.vn/about'><a >Về chúng tôi</a></Link>
                                    </li>
                                    <li>
                                        <Link href='https://tcom.vn/project'><a >Dự án</a></Link>
                                    </li>
                                    <li>
                                        <Link href='https://tcom.vn/news'><a >Tin tức</a></Link>
                                    </li>
                                    <li>
                                        <a href='https://tuyendung.tcom.vn/' target={'_blank'}>Tuyển dụng</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-12 col-xl-3 customService'>
                            <div className='service text-white'>
                                <h3 className='title'> CÁC DỊCH VỤ</h3>
                            </div>
                            <div className='content customDisplayFlex'>
                                <ul>
                                    {service.map((item, index) => {
                                        if (index % 3 === 0)
                                            return (
                                                <li key={index}><a href={`https://tcom.vn/service/${item?.seo_url}`}>{item?.title}</a></li>
                                            );
                                    })}
                                    <li><a href={`https://tcom.vn/offshore`}>{'Offshore'}</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-12 col-xl-3 customService'>
                            <div className='content customDisplayFlex'>
                                <ul className='cusstomMarginTopUL'>
                                    {service.map((item, index) => {
                                        if (index % 3 === 1)
                                            return (
                                                <li key={index}><a href={`https://tcom.vn/service/${item?.seo_url}`}>{item?.title}</a></li>
                                            );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className='col-12 col-xl-2'>
                            <div className='content pt-5 customContent'>
                                <ul className='customMt-76'>
                                    {service.map((item, index) => {
                                        if (index % 3 === 1)
                                            return (
                                                <li key={index}><a href={`https://tcom.vn/service/${item?.seo_url}`}>{item?.title}</a></li>
                                            );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='customRowFooter2-MB d-none pt-5'>
                        <div className='row'>
                            <div className='col-8 customService'>
                                <div className='service text-white'>
                                    <h3 className='title'> CÁC DỊCH VỤ</h3>
                                </div>
                                <div className='content'>
                                    <ul>
                                        {service.map((item, index) => {
                                            return (
                                                <li key={index}><a href={`https://tcom.vn/service/${item?.seo_url}`}>{item?.title}</a></li>
                                            );
                                        })}
                                        <li> <Link href='https://tcom.vn/offshore'><a>Offshore</a></Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-4 customService menuFooterMB'>
                                <div className='mainMenuFooter text-white'>
                                    <h3 className='title'>GIỚI THIỆU</h3>
                                </div>
                                <div className='content'>
                                    <ul>
                                        {/* <li><Link href='https://tcom.vn'><a >Trang chủ</a></Link></li> */}
                                        <li><Link href='https://tcom.vn/about'><a>Về chúng tôi</a></Link></li>
                                        <li><Link href='https://tcom.vn/service'><a>Dịch vụ</a></Link></li>
                                        <li><Link href='https://tcom.vn/project'><a>Dự án</a></Link></li>
                                        <li><Link href='https://tcom.vn/contact'><a>Liên hệ</a></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className='rules'>
                            <p>
                                <a className='text-white' style={{textDecoration: "underline", fontSize:16}} href='http://'>Điều khoản dịch vụ</a>
                            </p>
                            <p>
                                <a className='text-white' style={{textDecoration: "underline", fontSize:16}} href='http://'>Chính sách bảo mật</a>
                            </p>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className='copyright'><p>Copyright © 2022 TCOM Corporation. All rights reserved.</p></div>
        </footer>

    );

}